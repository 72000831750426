import { Title, Button } from "@vshp/ui-components";
import offerBg from "../../../public/images/try.jpg";
import { URL_PROFILE } from "../../config/config";
import Image from "next/image";

function Offer() {
  return (
    <section className="page__block page__block_cover page__block_center page__block_cover_light">
      <Image className="page__block-img" src={offerBg} placeholder="blur" alt="Начать обучение" />
      <div className="page__block-body">
        <Title type="h2" decor={false}>
          Хочешь получить профессию мечты?
        </Title>

        <Button href={`${URL_PROFILE}/register`} target="_blank" rel="noopener noreferrer">
          Начать обучение
        </Button>
      </div>
    </section>
  );
}

export default Offer;
