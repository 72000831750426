import { Title, Section } from "@vshp/ui-components";
import Slider from "../utils/Slider";
import { Image } from "antd";

import certificatesPicture1 from "../../../public/images/certificates/certificates_01.png";
import certificatesPicture2 from "../../../public/images/certificates/certificates_02.png";

const certificates = [
  {
    src: certificatesPicture1.src,
  },
  {
    src: certificatesPicture2.src,
  },
];

const Certificates = () => {
  const settings = {
    infinite: true,
  };

  return (
    <Section border={true}>
      <Title type="h2">Документы об образовании</Title>
      <div className="wysiwyg">
        <p>Выпускники колледжа получают диплом государственного образца о&nbsp;среднем профессиональном обучении.</p>
      </div>
      <div className="slider-certificates">
        <Slider className="slider__dots_accent" sliderSettings={settings}>
          {certificates.map((item, key) => (
            <div className="slider__item" key={key}>
              <div className="slider-certificates__item">
                <div className="slider-certificates__img-wrap">
                  <Image src={item.src} alt="Документ" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  );
};

export default Certificates;
