import React from "react";
import { Title, Section } from "@vshp/ui-components";
import { PlayBtnIcon } from "../utils/svg";
import Slider from "../utils/Slider";
import { useSelector } from "react-redux";

function VideosInfo() {
  const { videos } = useSelector((state) => state.videos);

  const settings = {
    slidesToShow: 3,
    infinite: videos.length > 3,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          arrows: false,
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1075,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    videos.length > 0 && (
      <Section className="videos" border={true}>
        <Title type="h2">ВИДЕО КОЛЛЕДЖА «ВЫСШАЯ ШКОЛА ПРЕДПРИНИМАТЕЛЬСТВА»</Title>
        <div className="videos__slider">
          <Slider className="videos__slider-inner slider__dots_accent" sliderSettings={settings}>
            {videos.map((video, key) => (
              <div className="slider__item" key={key}>
                <div className="videos__item">
                  <a href={video.attributes.url} className="videos__img-wrap" target="_blank" rel="noopener noreferrer">
                    <div className="videos__img-icon">
                      <PlayBtnIcon />
                    </div>
                    <img className="videos__img" src={video.attributes.photo.preview} alt={video.attributes.title} />
                  </a>

                  <h3 className="t-h3 videos__title">
                    <a href={video.attributes.url} target="_blank" rel="noopener noreferrer">
                      {video.attributes.title}
                    </a>
                  </h3>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Section>
    )
  );
}

export default VideosInfo;
