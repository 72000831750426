import { Title, Section } from "@vshp/ui-components";
import PartnerItem from "./PartnerItem";

import partnersPicture1 from "../../../public/images/partners/partners_01.png";
import partnersPicture2 from "../../../public/images/partners/partners_02.png";
import partnersPicture3 from "../../../public/images/partners/partners_03.png";
import partnersPicture4 from "../../../public/images/partners/partners_04.png";
import partnersPicture5 from "../../../public/images/partners/partners_05.png";

const partners = [
  {
    url: "https://vshp.online/partners/swiss-international-institute-lausanne",
    logo: partnersPicture1,
    title: "Swiss International Institute Lausanne",
  },
  {
    url: "https://vshp.online/partners/finansovyy-universitet-pri-pravitelstve-rossiyskoy-federatsii",
    logo: partnersPicture2,
    title: "Финансовый университет при правительстве Российской Федерации",
  },
  {
    url: "https://vshp.online/partners/basketball_club_tver",
    logo: partnersPicture3,
    title: "Баскетбольный клуб «Тверь»",
  },
  {
    url: "https://vshp.online/partners/universitet-sinergiya",
    logo: partnersPicture4,
    title: "Университет «Синергия»",
  },
  {
    url: "https://vshp.online/partners/sberbank",
    logo: partnersPicture5,
    title: "Сбербанк",
  },
];

function Partners({ border }) {
  return (
    <Section border={border}>
      <Title type="h2">МЫ СОТРУДНИЧАЕМ</Title>
      <Section.BodySmall className="wysiwyg">
        <p>
          Сотрудничество с&nbsp;различными компаниями и&nbsp;совместные проекты с&nbsp;другими учебными заведениями помогают нам совместно с&nbsp;учащимися и&nbsp;выпускниками достигать самых высоких
          результатов:
        </p>
      </Section.BodySmall>
      <PartnerItem partners={partners} />
      <div className="text-center">
        <a className="btn btn_outline btn_max-width-sm" href="https://vshp.online/partners" target="_blank" rel="noopener noreferrer">
          Показать всех партнеров
        </a>
      </div>
    </Section>
  );
}

export default Partners;
