import { Title, Button } from "@vshp/ui-components";
import teachersBg from "../../../public/images/teachers.jpg";

import teacher01 from "../../../public/images/teachers/teacher01.png";
import teacher02 from "../../../public/images/teachers/teacher02.jpg";
import teacher03 from "../../../public/images/teachers/teacher03.jpeg";
import teacher04 from "../../../public/images/teachers/teacher04.jpeg";
import teacher05 from "../../../public/images/teachers/teacher05.jpg";
import teacher06 from "../../../public/images/teachers/teacher06.jpg";
import teacher07 from "../../../public/images/teachers/teacher07.jpg";
import teacher08 from "../../../public/images/teachers/teacher08.jpg";
import teacher09 from "../../../public/images/teachers/teacher09.jpg";

import Slider from "../utils/Slider";
import Image from "next/image";
import { URL } from "../../config/config";

const teachers = [
  {
    photo: teacher01,
    name: "Царёва Наталья Евгеньевна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `<p>Кандидат экономических наук, доцент и&nbsp;преподаватель кафедры &laquo;Финансы&raquo; Тверского государственного университета; член и&nbsp;секретарь ученого Совета Института экономики и&nbsp;управления ТвГУ. Является независимым экспертом в&nbsp;работе аттестационной комиссии Управления федерального казначейства по&nbsp;Тверской области.</p>
    `,
  },
  {
    photo: teacher02,
    name: "Толкаченко Галина Львовна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства». Проректор института «Высшая школа предпринимательства».",
    description: `<p>Кандидат экономических наук, профессор, почетный работник высшего профессионального образования. Обладает званием &laquo;Почетный профессор Тверского государственного университета&raquo;.</p>
    <p>Преподаватель по&nbsp;направлениям &laquo;Банковское дело&raquo; и&nbsp;&laquo;Коммерция (по&nbsp;отраслям)&raquo;. Создатель ряда авторских курсов по&nbsp;экономике и&nbsp;финансовому менеджменту, автор более 150-ти научных статей, 8-ми монографий по&nbsp;вопросам инвестиционной деятельности, 4-х учебных пособий с&nbsp;грифом УМО.</p>
    `,
  },
  {
    photo: teacher03,
    name: "Ткачёв Павел Сергеевич",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `<p>Кандидат философских наук, эксперт чемпионата WorldSkills. Ведущий преподаватель по направлению «Информационные системы и программирование».</p>
    <p>Образовательная деятельность:</p>
    <ul>
      <li>Заведующий кафедрой информационных технологий института «Высшая школа предпринимательства»;</li>
      <li>Проректор по информатизации.</li>
    </ul>
    `,
  },
  {
    photo: teacher04,
    name: "Полторыхина Марина Владимировна",
    position: "Преподаватель по&nbsp;направлению &laquo;Право и&nbsp;организация социального обеспечения&raquo;.",
    description: `Помощник сенатора Российской Федерации Епишина Андрея Николаевича, сотрудник Региональной общественной приемной Председателя партии &laquo;Единая Россия&raquo; Дмитрия Анатольевича Медведева.<br />Стаж работы по&nbsp;специальности&nbsp;&mdash; более 7&nbsp;лет.`,
  },
  {
    photo: teacher05,
    name: "Толкаченко Оксана Юрьевна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `<p>Кандидат экономических наук, доцент. Специалист в&nbsp;области экономики, менеджмента и&nbsp;психологии.</p>
    <p>Является экспертом в&nbsp;области проведения государственной аттестации образовательного учреждения. Также занимается разработкой основных образовательных программ бакалавриата. Автор более 60&nbsp;научных публикаций.</p>
    <p>Образовательная деятельность:</p>
    <ul>
      <li>Преподаватель Тверского государственного университета — ТвГУ.</li>
    </ul>`,
  },
  {
    photo: teacher06,
    name: "Кулыгина Ольга Владимировна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `Помощник сенатора Российской Федерации Л.Н. Скаковской по&nbsp;работе в&nbsp;Тверской области, сотрудник региональной общественной приемной политической Партии &laquo;Единая Россия&raquo; в&nbsp;Тверской области. Преподаватель по&nbsp;направлению &laquo;Юриспруденция&raquo;.<br/>
    Стаж работы по&nbsp;специальности&nbsp;&mdash; более 25&nbsp;лет.
    `,
  },
  {
    photo: teacher07,
    name: "Зюзя Евгения Викторовна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `Доктор экономических наук, коуч и&nbsp;психотерапевт с&nbsp;опытом работы свыше 20&nbsp;лет. автор 160 опубликованных научных работ, в&nbsp;том числе 11&nbsp;учебных пособий и&nbsp;монографий. Финансовый консультант, консультант по&nbsp;управлению рисками и&nbsp;бизнес процессами.
    `,
  },
  {
    photo: teacher08,
    name: "Желтовских Елена Валерьевна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `Участник Всероссийских и&nbsp;региональных конкурсов профессионального мастерства. Участник региональных чемпионатов &laquo;Молодые профессионалы&raquo; (Worldskills Russia).
    Принимает участие в&nbsp;создание качественного контента по&nbsp;дисциплинам на&nbsp;основе применяемых дистанционных образовательных технологий и&nbsp;электронного обучения
    `,
  },
  {
    photo: teacher09,
    name: "Климова Татьяна Юрьевна",
    position: "Преподаватель образовательного комплекса «Высшая школа предпринимательства»",
    description: `Награждена почетными грамотами губернатора Тверской области и&nbsp;Министерства образования Тверской области. Преподаватель дисциплины &laquo;Информатика&raquo; и&nbsp;&laquo;Информационные технологии в&nbsp;профессиональной деятельности&raquo;.`,
  },
];

function Teachers() {
  const settings = {
    infinite: true,
  };

  return (
    <section className="page__block page__block_cover">
      <Image className="page__block-img" src={teachersBg} alt="Преподаватели" placeholder="blur" />
      <div className="page__block-body">
        <Title type="h2" decor={false}>
          Преподаватели
        </Title>

        <Slider className="slider-description" sliderSettings={settings}>
          {teachers.map((teacher, key) => (
            <div className="slider__item" key={key}>
              <div className="slider-description__item">
                <div className="slider-description__header">
                  <Image className="slider-description__photo" src={teacher.photo} alt={teacher.name} placeholder="blur" />
                  <div className="slider-description__name">{teacher.name}</div>
                  <div className="slider-description__post" dangerouslySetInnerHTML={{ __html: teacher.position }}></div>
                </div>
                <div className="slider-description__content" dangerouslySetInnerHTML={{ __html: teacher.description }}></div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="text-center">
          <Button spaceTop={true} href={`${URL}/teachers`} target="_blank" rel="noopener noreferrer">
            Узнать больше преподавателей
          </Button>
        </div>
      </div>
    </section>
  );
}

export default Teachers;
