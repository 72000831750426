import { Card } from "@vshp/ui-components";
import { useSelector } from "react-redux";
import Link from "next/link";

function ProgramCard(props) {
  const { programsDegrees } = useSelector((state) => state.programs);
  const { item, slug } = props;
  let degree;

  if (!slug) {
    degree = programsDegrees.filter((el) => parseInt(el.id, 10) === item.attributes["degree-id"]);
  }

  const planLink = `${slug ? slug : degree[0].attributes.slug}/${item.id}`;

  const programCardLink = ({ children, ...props }) => {
    return (
      <Link href={`/programs/${planLink}`} {...props}>
        {children}
      </Link>
    );
  };

  return (
    <Card className="programs-feed__item" btnText="Подробнее" cover={<img alt={item.attributes.title} src={item.attributes.photo.preview} />} title={item.attributes.title} link={programCardLink}>
      <Card.Row>
        <Card.Title>Уровень подготовки</Card.Title>
        <Card.Subtitle>{item.attributes["degree-name"]}</Card.Subtitle>
      </Card.Row>
      {item.attributes.specialty != null && (
        <Card.Row>
          <Card.Title>Специальность</Card.Title>
          <Card.Subtitle>{item.attributes.specialty}</Card.Subtitle>
        </Card.Row>
      )}
      <Card.Row>
        <Card.Title>Язык</Card.Title>
        <Card.Subtitle>{item.attributes["language-name"]}</Card.Subtitle>
      </Card.Row>
    </Card>
  );
}

export default ProgramCard;
