import { Title, Section } from "@vshp/ui-components";
import { PlayBtnIcon } from "../utils/svg";
import Slider from "../utils/Slider";

import reviewPicture1 from "../../../public/images/reviews/review_01.png";
import reviewPicture2 from "../../../public/images/reviews/review_02.png";
import reviewPicture3 from "../../../public/images/reviews/review_03.png";
import reviewPicture4 from "../../../public/images/reviews/review_04.jpg";
import reviewPicture5 from "../../../public/images/reviews/review_05.jpg";
import reviewPicture6 from "../../../public/images/reviews/review_06.jpg";
import reviewPicture7 from "../../../public/images/reviews/review_07.jpg";
import reviewPicture8 from "../../../public/images/reviews/review_08.jpg";
import reviewPicture9 from "../../../public/images/reviews/review_09.jpg";
import reviewPicture10 from "../../../public/images/reviews/review_10.jpg";
import Image from "next/image";

const reviews = [
  {
    url: "https://www.youtube.com/watch?v=SnYWsZ8erhU&list=PL_1SvszPJyNVP-JInCPcDf0G1AegpMG5Z&index=7",
    preview: reviewPicture1,
  },
  {
    url: "https://youtu.be/zcESrSUCjx0",
    preview: reviewPicture2,
  },
  {
    url: "https://www.youtube.com/watch?v=jF5hcXb1i7c&list=PL_1SvszPJyNVP-JInCPcDf0G1AegpMG5Z&index=10",
    preview: reviewPicture3,
  },
  {
    url: "https://www.youtube.com/watch?v=JJP82BtefaI",
    preview: reviewPicture4,
  },
  {
    url: "https://www.youtube.com/watch?v=XjNcmdRdoD0",
    preview: reviewPicture5,
  },
  {
    url: "https://www.youtube.com/watch?v=USjJatnkwGA",
    preview: reviewPicture6,
  },
  {
    url: "https://www.youtube.com/watch?v=N5jnblpRfcA",
    preview: reviewPicture7,
  },
  {
    url: "https://www.youtube.com/watch?v=5b5vm4pKxsc",
    preview: reviewPicture8,
  },
  {
    url: "https://www.youtube.com/watch?v=FSC50t1BCBk",
    preview: reviewPicture9,
  },
  {
    url: "https://www.youtube.com/watch?v=IlBePtKZOH8",
    preview: reviewPicture10,
  },
];

function Reviews() {
  const settings = {
    slidesToShow: 3,
    infinite: reviews.length > 3,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          arrows: false,
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 1075,
        settings: {
          arrows: false,
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Section className="videos" border={true}>
      <Title type="h2">ОТЗЫВЫ</Title>
      <div className="videos__slider">
        <Slider className="videos__slider-inner slider__dots_accent" sliderSettings={settings}>
          {reviews.map((review, key) => (
            <div className="slider__item" key={key}>
              <div className="videos__item">
                <a href={review.url} className="videos__img-wrap" target="_blank" rel="noopener noreferrer">
                  <div className="videos__img-icon">
                    <PlayBtnIcon />
                  </div>
                  <Image className="videos__img" src={review.preview} alt="ОТЗЫВ" placeholder="blur" />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  );
}

export default Reviews;
