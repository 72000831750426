import greetingBg from "../../../public/images/diploma.png";
import rector from "../../../public/images/rector.jpg";
import { Title } from "@vshp/ui-components";
import Image from "next/image";

function Greeting() {
  return (
    <section className="greeting page__block page__block_cover page__block_cover_white">
      <Image className="page__block-img" src={greetingBg} alt="Обращение руководителя" placeholder="blur" />
      <div className="page__block-body">
        <Title type="h2" decor={false} className="greeting__title-top">
          Уважаемые абитуриенты и&nbsp;студенты!
        </Title>

        <div className="greeting__body">
          <div className="greeting__col">
          <Image className="greeting__photo" src={rector} alt="Аллабян Максим Геннадьевич" placeholder="blur" />
            <div className="greeting__post">
              Кандидат экономических наук, доцент, федеральный эксперт Министерства науки и&nbsp;высшего образования Российской Федерации, директор колледжа «Высшая школа предпринимательства»
            </div>
            <div className="greeting__name">Аллабян Максим Геннадьевич</div>
          </div>
          <div className="greeting__col wysiwyg">
            <Title type="block" decor={false} className="greeting__title">
              Уважаемые абитуриенты и&nbsp;студенты!
            </Title>
            <div className="greeting__text">
              Быстрорастущий спрос на&nbsp;специалистов в&nbsp;области информационных технологий, юриспруденции и&nbsp;малого бизнеса стимулирует нас разрабатывать и&nbsp;предлагать нашим учащимся
              специальности по&nbsp;данным направлениям. Благодаря нашей способности адаптироваться под потребности рынка труда, наши выпускники легко находят работу и&nbsp;трудоустраиваются
              на&nbsp;выгодных для них условиях. В&nbsp;дальнейшем мы&nbsp;планируем продолжить расширение спектра востребованных специальностей в&nbsp;нашем колледже, чтобы выпускники получали
              максимально возможные шансы отличного трудоустройства.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Greeting;
