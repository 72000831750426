import React from "react";
import Link from "next/link";
import { useSelector } from "react-redux";

import { Title, Section, Steps, LinkDecor } from "@vshp/ui-components";
import { fetchOrgSettings } from "../actions/settings";
import { fetchPrograms, fetchProgramsDegrees } from "../actions/programs";
import { fetchOrgContacts } from "../actions/contacts";
import { fetchPostsRecent } from "../actions/posts";
import { fetchVideos } from "../actions/videos";

import Applicants from "../components/shared/Applicants";
import Contacts from "../components/shared/Contacts";
import Accreditation from "../components/shared/Accreditation";
import Consultation from "../components/shared/Consultation";
import Videos from "../components/shared/Videos";
import Programs from "../components/shared/Programs";
import Greeting from "../components/shared/Greeting";
import Teachers from "../components/shared/Teachers";
import Certificates from "../components/shared/Certificates";
import Reviews from "../components/shared/Reviews";
import Partners from "../components/shared/Partners";
import Faq from "../components/shared/Faq";
import Offer from "../components/shared/Offer";
import Life from "../components/shared/Life";
import PostsRecent from "../components/shared/PostsRecent";
import VideosInfo from "../components/shared/VideosInfo";

import { wrapper } from "../store";
import { ADVANTAGES_INFO } from "../constants/advantages";

export const getServerSideProps = wrapper.getServerSideProps((store) => async () => {
  await store.dispatch(fetchOrgSettings());
  await store.dispatch(fetchPrograms());
  await store.dispatch(fetchProgramsDegrees());
  await store.dispatch(fetchOrgContacts());
  await store.dispatch(fetchPostsRecent());
  await store.dispatch(fetchVideos());
});

function Home() {
  const { settingsOrg } = useSelector((state) => state.settingsOrg);

  return (
    <div className="main">
      <div className="page__header main__intro intro">
        <div className="intro__video">
          <video loop autoPlay muted playsInline preload="auto">
            <source src="/video/vshp.ogv" type='video/ogg; codecs="theora, vorbis"' />
            <source src="/video/vshp.webm" type='video/webm; codecs="vp8, vorbis"' />
            <source src="/video/vshp.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
          </video>
        </div>
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title main__intro-title">
            <span dangerouslySetInnerHTML={{ __html: settingsOrg.title }}></span>
          </Title>
          <div className="page__header-subtitle" dangerouslySetInnerHTML={{ __html: settingsOrg.description }}></div>
          <Link className="btn btn_primary page__header-btn" href={settingsOrg.pages[0].children[0].slug}>
            {settingsOrg.pages[0].children[0].title}
          </Link>
        </div>
      </div>

      <div className="page__blocks">
        <Section className="wysiwyg" border={true}>
          <Title type="h2">О колледже</Title>
          <Section.BodySmall>
            <p>
              Образовательная миссия колледжа заключается в&nbsp;подготовке высококвалифицированных кадров для современного рынка труда. Особое внимание уделяется подготовке специалистов для
              стремительно растущих рынков фриланса и&nbsp;удаленной работы. Для абитуриентов предлагаются максимально востребованные специальности.
            </p>
            <p>
              Концепция организации образовательного процесса колледжа &laquo;Высшая школа предпринимательства&raquo; включает в&nbsp;себя привлечение максимально квалифицированных преподавателей
              с&nbsp;опытом работы в&nbsp;соответствующих отраслях; разработку учебных планов, содержащих исключительно необходимые предметы, формирующие комплекс профессиональных компетенций
              у&nbsp;студентов; применение самых современных систем онлайн-образования.
            </p>
          </Section.BodySmall>
        </Section>

        <Section className="note wysiwyg">
          <Title type="h2">Преимущества колледжа</Title>

          <div className="list-cards">
            {ADVANTAGES_INFO.map(({ title }, id) => (
              <Link href={`advantages/${id + 1}`} className="list-cards__item" key={id}>
                {title}
              </Link>
            ))}
          </div>
        </Section>

        <Consultation />
        <Videos />
        <Greeting />
        <Programs />
        <VideosInfo />

        <Section border={true}>
          <Title type="h2">Как проходит обучение</Title>
          <Steps>
            <Steps.Item
              title="Знакомство"
              description="Сотрудник приемной комиссии познакомит вас с&nbsp;учебным заведением, поможет с&nbsp;выбором специальности, проведет для вас экскурсию и&nbsp;расскажет историю колледжа."
            ></Steps.Item>
            <Steps.Item
              title="Поступление"
              description="Для поступления необходим полный пакет документов (перечень предоставит специалист приемной комиссии). На&nbsp;основании этих документов специалист подготовит договор на&nbsp;обучение, а&nbsp;также создаст вам личный кабинет на&nbsp;образовательном портале &laquo;ВШП&raquo;."
            />
            <Steps.Item
              title="Обучение"
              description="Студенты очной формы обучения посещают лекции и&nbsp;семинары, которые проходят в&nbsp;здании колледжа, согласно классической модели очного обучения. Студенты заочной формы с&nbsp;применением дистанционных технологий учатся через мобильное приложение или образовательный портал, доступный с&nbsp;любого устройства с&nbsp;возможностью выхода в&nbsp;интернет. Сессии у&nbsp;студентов заочной формы обучения с&nbsp;применением дистанционных технологий также проходят на&nbsp;образовательном портале, без личного присутствия."
            />
            <Steps.Item
              title="Студенческая жизнь"
              description="В&nbsp;течение учебного года учащиеся ведут активную студенческую жизнь: участвуют в&nbsp;олимпиадах и&nbsp;конкурсах, проявляют таланты в&nbsp;спортивных и&nbsp;культурных мероприятиях, посещают тренинги, семинары и&nbsp;познавательные лекции в&nbsp;области информационных технологий, логистики, предпринимательства, экономики и&nbsp;права."
            />
            <Steps.Item
              title="Аттестация"
              description="Аттестационные мероприятия проходят посредством системы тестирования на&nbsp;образовательном портале &laquo;ВШП&raquo; (для студентов заочной формы обучения с&nbsp;применением дистанционных технологий) или в&nbsp;виде классической экзаменационной процедуры (для студентов очной формы обучения). Итоговая аттестация проходит в&nbsp;виде экзамена и/или защиты диплома."
            />
            <Steps.Item
              title="Практика и трудоустройство"
              description="Колледж &laquo;Высшая школа предпринимательства&raquo; направляет студентов на&nbsp;практику в&nbsp;компании-партнеры с&nbsp;дальнейшим трудоустройством. Например, в&nbsp;ПАО Сбербанк, Страховую группу &laquo;Уралсиб&raquo;, Социальный фонд России и&nbsp;другие."
            />
            <Steps.Item
              title="Выпуск"
              description="Каждый год колледж организовывает торжественное вручение дипломов. По&nbsp;традиции выпуск завершают наряженные в&nbsp;мантии выпускники, которые бросают в&nbsp;небо шапочки-конфедератки."
            />
            <Steps.Item
              title="Дальнейшее образование"
              description="Комплекс &laquo;Высшая школа предпринимательства&raquo; предоставляет выпускникам своего колледжа дипломы государственного образца, на&nbsp;основании которых можно поступить в&nbsp;институт &laquo;Высшая школа предпринимательства&raquo; или вузы-партнеры без результатов ЕГЭ."
            />
          </Steps>
        </Section>

        <Life />
        <Teachers />
        <Certificates />

        <Section border={true}>
          <Title type="h2">ОФИЦИАЛЬНЫЕ ДОКУМЕНТЫ</Title>
          <div className="wysiwyg__documents">
            <LinkDecor link="https://islod.obrnadzor.gov.ru/rlic/details/da9464de-2fe1-40b1-a099-d8e079a607fb/">Лицензия на осуществление образовательной деятельности</LinkDecor>
            <LinkDecor link="https://islod.obrnadzor.gov.ru/accredreestr/details/39a1f2f9-e1f5-4299-b7aa-fe09157ab662/1/">Свидетельство о государственной аккредитации</LinkDecor>
            <LinkDecor link="https://cdn.vshp.online/docs/8f8d/Kreditovanie_removed.pdf">Образовательное кредитование с государственной поддержкой</LinkDecor>
            <LinkDecor link="https://cdn.vshp.online/docs/845b/Ofitsialnaya_prezentatsiya_Chastnogo_uchrezhdeniya_professionalnogo_obrazovaniya__Vysshaya_shkola_predprinimatelstva_.pdf">
              Официальная презентация Частного учреждения профессионального образования &laquo;Высшая школа предпринимательства&raquo;
            </LinkDecor>
          </div>
        </Section>

        <Reviews />
        <Partners />

        <Applicants slug={settingsOrg.pages[0].children[1].slug} />
        <Accreditation />
        <Faq />
        <Offer />
        <PostsRecent />
        <Contacts />
      </div>
    </div>
  );
}

export default Home;
