import { useSelector } from "react-redux";
import { Title, Section } from "@vshp/ui-components";
import MapContacts from "./MapContacts";
import { MapIcon, PhoneIcon, MailIcon } from "../utils/svg";
import { formatPhone } from "../utils/formatPhone";

function Contacts() {
  const { contactsOrg } = useSelector((state) => state.contacts);

  const regex = /(?<=\()[^)]+(?=\))/g;

  const formatPhoneForCall = (phone) => {
    let currentText = "";

    const textArrowArray = phone.match(regex);
    textArrowArray && textArrowArray.map((text) => !parseInt(text[0], 10) && (currentText = `(${text})`));

    return formatPhone(phone)
      .replace(currentText, "")
      .replace(/[^+\d]+/g, "");
  };

  return (
    <>
      <Section>
        <Title type="h2">КОНТАКТЫ</Title>
        <Section.BodySmall>
          <div className="wysiwyg">
            <p>
              Мы&nbsp;всегда готовы помочь Вам с&nbsp;выбором образовательной программы и&nbsp;проконсультировать Вас по&nbsp;вопросам поступления по&nbsp;телефону, через сообщения на&nbsp;сайте или
              в&nbsp;зданиях нашего образовательного комплекса по&nbsp;адресам:
            </p>
          </div>
        </Section.BodySmall>
      </Section>
      {contactsOrg.length > 0 && (
        <>
          {contactsOrg.map(({ attributes: { organization, address, phone, email, "map-lat": lat, "map-lon": lon, "map-zoom": zoom } }, index) => (
            <Section key={index}>
              <div className="contacts__block">
                <div className="contacts__item contacts__item_medium">
                  <MapIcon />
                  <div className="contacts__item-label">Адрес {organization}</div>
                  <div className="contacts__item-value">{address}</div>
                </div>

                {phone && phone.length > 0 && (
                  <div className="contacts__item contacts__item_short">
                    <PhoneIcon />
                    <div className="contacts__item-label">Телефон</div>
                    <a href={`tel:${formatPhoneForCall(phone)}`} className="contacts__item-value">
                      {phone}
                    </a>
                  </div>
                )}

                {email && email.length > 0 && (
                  <div className="contacts__item contacts__item_short">
                    <MailIcon />
                    <div className="contacts__item-label">Электронная почта</div>
                    <a href={`mailto:${email}`} className="contacts__item-value">
                      {email}
                    </a>
                  </div>
                )}
              </div>
            </Section>
          ))}
          <MapContacts />
        </>
      )}
    </>
  );
}

export default Contacts;
