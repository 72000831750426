import { Title, Section } from "@vshp/ui-components";
import Slider from "../utils/Slider";
import { Image } from "antd";

import lifePicture1 from "../../../public/images/life/life_01.jpg";
import lifePicture2 from "../../../public/images/life/life_02.jpg";
import lifePicture3 from "../../../public/images/life/life_03.jpg";
import lifePicture4 from "../../../public/images/life/life_04.jpg";
import lifePicture5 from "../../../public/images/life/life_05.jpg";
import lifePicture6 from "../../../public/images/life/life_06.jpg";
import lifePicture7 from "../../../public/images/life/life_07.jpg";
import lifePicture8 from "../../../public/images/life/life_08.jpg";
import lifePicture9 from "../../../public/images/life/life_09.jpg";
import lifePicture10 from "../../../public/images/life/life_10.jpg";
import lifePicture11 from "../../../public/images/life/life_11.jpg";

function Life() {
  const settings = {
    slidesToShow: 1,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1106,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <Section className="life">
      <Title type="h2">Мероприятия и&nbsp;студенческая жизнь</Title>
      <div className="life__slider">
        <Slider className="life__slider-inner slider__dots_accent" sliderSettings={settings}>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture1.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture2.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture3.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture4.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture5.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture6.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture7.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture8.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture9.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture10.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
          <div className="slider__item">
            <div className="life__item">
              <div className="life__img-wrap">
                <Image src={lifePicture11.src} alt="Мероприятия" />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </Section>
  );
}

export default Life;
