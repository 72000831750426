import { Title, Section, Collapse } from "@vshp/ui-components";

function Faq() {
  return (
    <Section>
      <Title type="h2">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</Title>
      <Collapse>
        <Collapse.Item
          header="Сколько лет длится обучение в вашем колледже?"
          content="Сроки обучения зависят от&nbsp;Вашего документа об&nbsp;образовании, формы обучения и&nbsp;направления, на&nbsp;которое вы&nbsp;хотите поступить. На&nbsp;основании аттестата об&nbsp;основном общем образовании (после 9&nbsp;классов) очное обучение длится от&nbsp;2&nbsp;лет 10&nbsp;месяцев до&nbsp;3&nbsp;лет 10&nbsp;месяцев, заочное обучение&nbsp;&mdash; от&nbsp;3&nbsp;лет 10&nbsp;месяцев до&nbsp;4&nbsp;лет 10&nbsp;месяцев. На&nbsp;основании аттестата о&nbsp;среднем образовании (после 11&nbsp;классов) очное обучение длится от&nbsp;1&nbsp;года 10&nbsp;месяцев до&nbsp;2&nbsp;лет 10&nbsp;месяцев, заочное обучение&nbsp;&mdash; от&nbsp;2&nbsp;лет 10&nbsp;месяцев до&nbsp;3&nbsp;лет 10&nbsp;месяцев."
        />
        <Collapse.Item
          header="По каким направлениям в вашем колледже есть аккредитация?"
          content={`Все лицензированные и аккредитованные программы нашей образовательной организации вы можете посмотреть <a href="https://college.vshp.online" target="_blank" rel="noopener noreferrer">на официальном сайте колледжа</a> в разделе «Сведения об образовательной организации» в подразделах «Документы» и «Образование»`}
        />
        <Collapse.Item
          header="Сколько стоит обучение в вашем колледже?"
          content={`Стоимость обучения для абитуриентов каждый год меняется на&nbsp;основании приказа руководителя образовательной организации. Для студентов стоимость не&nbsp;меняется и&nbsp;может быть повышена только на&nbsp;%&nbsp;инфляции. Более подробную информацию о&nbsp;стоимости каждой из&nbsp;программ колледжа вы&nbsp;можете <a href="https://vshp.online/programs/kolledzh" target="_blank" rel="noopener noreferrer">узнать на&nbsp;образовательном портале</a>.`}
        />
        <Collapse.Item
          header="Где взять список учебников и других материалов, необходимых для учебы?"
          content="Список литературы по&nbsp;каждому предмету находится в&nbsp;личном кабинете каждого студента, также имеется электронно-библиотечная система для доступа ко&nbsp;всей нужной литературе с&nbsp;любой точки мира. Для очных групп в&nbsp;колледже по&nbsp;адресу&nbsp;г. Тверь, ул. Спартака, д. 26а есть библиотека с&nbsp;печатными изданиями всей необходимой литературы."
        />
        <Collapse.Item
          header="Как загрузить работу на сайт?"
          content="Для этого необходимо сохранить работу в&nbsp;указанном на&nbsp;сайте формате и&nbsp;запомнить где&nbsp;Вы ее&nbsp;сохранили на&nbsp;Вашем устройстве. В&nbsp;личном кабинете выберите нужную дисциплину в&nbsp;учебном плане и&nbsp;нажмите на&nbsp;кнопку &laquo;Загрузить&raquo;. После этого сообщить куратору, что работа загружена. Как только преподаватель проверит Вашу работу, куратор выставит оценку или сообщит о&nbsp;необходимости исправить работу."
        />
      </Collapse>
      <div className="text-center">
        <a className="btn btn_primary btn_space_top" href="https://vshp.online/faq" target="_blank" rel="noopener noreferrer">
          Посмотреть все вопросы
        </a>
      </div>
    </Section>
  );
}

export default Faq;
